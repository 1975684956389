<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-date-picker
            size="small"
            v-model="orderCountDate"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleDateChange"
        >
        </el-date-picker>
      </div>
    </el-card>
    <!--    功能区域-->
<!--    <div style="margin: 20px 0">
      <el-button type="primary" @click="billBatch">批量开票</el-button>
    </div>-->
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="orderTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="60" align="center">
          <template #default="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="订单编号" align="center">
          <template #default="scope">{{ scope.row.outTradeNo }}</template>
        </el-table-column>
        <el-table-column label="订单金额" align="center">
          <template #default="scope">{{ scope.row.expenseMoney }}</template>
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template #default="scope">{{ scope.row.accountStatus == '开通' ? '首次开通' : '续费' }}</template>
        </el-table-column>
        <el-table-column label="支付时间" align="center">
          <template #default="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column label="账号周期" align="center">
          <template #default="scope">{{ scope.row.expenseCycle }}{{ scope.row.expenseChoice }}</template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template #default="scope">{{ scope.row.username }}</template>
        </el-table-column>
        <el-table-column label="开票状态" align="center">
          <template #default="scope">{{ scope.row.invoiceStatus == '0' ? '未申请' : '已申请' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" v-if="scope.row.invoiceStatus === 0" @click="handleBill(scope.row)">
              开具发票
            </el-button>
            <el-button size="mini" type="primary" v-if="scope.row.invoiceStatus === 1" @click="handleView(scope.row)">
              查看发票
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        title="开票申请"
        v-model="dialogVisible"
        :close-on-click-modal="false"
        width="40%">
      <el-form :model="invoiceModel"
               ref="invoiceFrom"
               label-width="150px"
               size="small">
        <el-form-item label="名称：">
          <el-input v-model="invoiceModel.taxName" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号：">
          <el-input v-model="invoiceModel.taxNumber" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="地址：">
          <el-input v-model="invoiceModel.address" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="电话：">
          <el-input v-model="invoiceModel.telephone" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="开户行：">
          <el-input v-model="invoiceModel.accountBank" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="账号：">
          <el-input v-model="invoiceModel.account" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="invoiceModel.email" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="微信：">
          <el-input v-model="invoiceModel.wechatNumber" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchBillConfirm('invoiceFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>

</template>

<script>

import {myOrderList} from "@/api/payOrder";
import {createInvoice} from "@/api/invoice";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  createTime: null,
  endTime: null,
};

const defaultInvoice = {
  taxName: null,
  taxNumber: null,
  address: null,
  telephone: null,
  accountBank: null,
  account: null,
  email: null,
  wechatNumber: null,
  orderIdList: [],
};


export default {
  name: 'MyOrder',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      orderCountDate: '',

      dialogVisible: false,
      isEditFlag: false,
      invoiceModel: Object.assign({}, defaultInvoice),

    }
  },
  created() {
    //this.initOrderCountDate();
    this.getTableList();
  },
  methods: {
    //订单日期
    initOrderCountDate() {
      //顾头不顾尾,不包括结束时间
      let start = new Date();
      start.setHours(0, 0, 0, 0); // 设置时分秒和毫秒为0
      const end = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 1);
      this.orderCountDate = [start, end];
    },
    //切换日期
    handleDateChange() {
      //顾头不顾尾,不包括结束时间
      this.getTableList();
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //列表
    getTableList() {
      this.listQuery.createTime = this.orderCountDate[0];
      this.listQuery.endTime = this.orderCountDate[1];
      this.listLoading = true;
      myOrderList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })

    },
    //多选
    handleSelectionChange(val) {
      this.ids = val.map(v => v.id);
    },
    //批量开票路由
    billBatch() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.dialogVisible = true;
      this.invoiceModel = Object.assign({}, defaultInvoice);
    },
    //开票
    handleBill(row) {
      this.dialogVisible = true;
      this.invoiceModel = Object.assign({}, row);
    },
    //开票提交
    handleBatchBillConfirm() {
      if (this.invoiceModel.id) {
        this.ids.push(this.invoiceModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }

      this.$confirm('确定开票吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.invoiceModel.orderIdList = this.ids;
        createInvoice(this.invoiceModel).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '已提交申请',
              type: 'success',
            });
            this.dialogVisible = false;
            this.getTableList();
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
        });

      });
    },
    //查看
    handleView(row) {
      this.$router.push({path: '/order-use', query: {oid: row.id}})
    },


  }
}
</script>
