import request from '@/utils/request';

//统计订单列表
export function myOrderList(data) {
  return request({
    url:'/payOrder/myOrderList',
    method : 'post',
    data : data
  })
}

